html{
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
}
h8{
  color: #E31E3F;
  font-size: 2em;
  margin-bottom: 1em;
}

i{
  color: #adadad;
}
body {
color: #ffffff;
  background-color: #212121;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
}

#Modal{
  color: black;
  position: relative;
  overflow-y: initial !important;
  height: 80vh;
  
}
#Center{
  display: flex;
  justify-content: center;
  align-items: center;
}

#MenuItems{
  display: flex;
  flex-direction: row;

}

#Left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
  width: 50%;
  border-right: 1px solid rgb(255, 255, 255);
  padding: 2%;
  
  
}

#Right{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  border-left: 1px solid rgb(255, 255, 255);
  flex-direction: column;
  padding: 2%;
  

}

@media screen and (max-width: 900px) {
  #MenuItems{
    display: flex;
    flex-direction: column;
  }
  #Left{
    width: 100%;
    border-right: none;
    
    
  }
  
  #Right{
width: 100%;
border-left: none;
  }

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

