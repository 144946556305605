/* crop image to be square */
img {
    position: relative;
    overflow: hidden;
    min-height: 30%;
    min-width: 30%;
    max-width: 30%;
    min-height: 30%;
}

#Container{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    height: 20%;
    color: white;
    
}

h1{
    font-size: 1.4em;
    margin: 0;
    padding: 1em;
    padding-top: 2em;
}

#Paragraph{
    font-size: 1em;
    margin: 0;
    padding: 2em;
}

/* mobile media query */
@media screen and (max-width: 900px) {
    img {
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
    }
    #Container {
        flex-direction: column;
        /* image fullsize responsive */

        
    }
    p{
        font-size: larger;
    }
}
    


