#navbar{
    justify-content: left;
    padding: 2em;
    background-color: #141414;
    
}



a { 
    color: inherit;     
    text-decoration: none; 
    font-size: larger;
    font-weight: bold;

    


}

#InnerNavbar{
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-between; 
  
    width: 50%;

    position: absolute;
    
    left: 50%;
    transform: translate(-50%, -50%);
    
    
}

/* Hide entire element if viewed from mobile */
@media screen and (max-width: 900px) {
    #InnerNavbar {
        display: none;
    }
    #navbar{
        padding: 1em;
    }
}