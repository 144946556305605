#ContactForm{
    color: white;
    background-color: #212121;
background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23e70012' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
    padding: 20px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    
}



/* Icons */

ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
}

    ul.icons li {
        display: inline-block;
        padding: 0 0.75em 0 0;
    }

        ul.icons li:last-child {
            padding-right: 0;
        }

        ul.icons li a {
            border-radius: 100%;
            box-shadow: inset 0 0 0 1px #ffffff;
            display: inline-block;
            height: 2.25rem;
            line-height: 2.25rem;
            text-align: center;
            width: 2.25rem;
        }

            ul.icons li a:hover {
                background-color: rgba(255, 255, 255, 0.075);
            }

            ul.icons li a:active {
                background-color: rgba(255, 255, 255, 0.175);
            }


input,
textarea
{
    color: black !important;
}

#Button{
    background-color: #ac0000;
    width: 100%;
    color: white;
}
/* reset */
form input,
form select,
form textarea,
form fieldset,
form optgroup,
form label,
.StripeElement {
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
form label,
form legend {
  font-size: 0.825rem;
  margin-bottom: 0.5rem;
}
/* border, padding, margin, width */
form input,
form select,
form textarea,
.StripeElement {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75em 1rem;
  margin-bottom: 1.5rem;
}
form input:focus,
form select:focus,
form textarea:focus,
.StripeElement:focus {
  background-color: white;
  outline-style: solid;
  outline-width: thin;
  outline-color: gray;
  outline-offset: -1px;
}
form [type="text"],
form [type="email"],
.StripeElement {
  width: 100%;
}
form [type="button"],
form [type="submit"],
form [type="reset"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}
form [type="button"]:focus,
form [type="submit"]:focus,
form [type="reset"]:focus {
  outline: none;
}

form select {
  text-transform: none;
}