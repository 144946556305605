#MenuDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
body{
    background-color: #000000;
}

