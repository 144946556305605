.wrapper{
    width: 50vh;
}

.menu-item-title{
    /* flex row space between */
    display: flex;
    justify-content: space-between;
    

}

.menu-item-description{
    margin-top: 0.3em;
    margin-bottom: 1em;
}