#GenButton{
   
    min-width: 20%;
    width: auto;
    height: 3rem;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 4px;
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: 1.25px;
    cursor: default;
    font-family: 'Roboto', sans-serif;
    background-color: #ac0000;
    
    
 

}

#GenButton:empty{
  display:none;
}
@media screen and (max-width: 900px) {

    #GenButton{
        margin-top: 10%;
        min-width: 100%;
    }


}



